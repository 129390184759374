import {
  type DataFunctionArgs,
  json,
  type MetaFunction,
  type ActionFunctionArgs,
} from '@remix-run/node';
import { Form, Link, redirect, useLoaderData } from '@remix-run/react';
import { Button } from '~/components/ui/button.tsx';
import { deleteWizardCookie } from '~/utils/analysis.server.ts';
import { getUserId } from '~/utils/auth.server.ts';

export const meta: MetaFunction = () => [{ title: 'Running App' }];

export async function loader({ request }: DataFunctionArgs) {
  const userId = await getUserId(request);

  return json({ userId });
}

export async function action({ request }: ActionFunctionArgs) {
  const deleteCookie = deleteWizardCookie(request);

  return redirect('/analysis/start', {
    headers: {
      'set-cookie': await deleteCookie.serialize(''),
    },
  });
}

export default function Index() {
  const { userId } = useLoaderData<typeof loader>();

  return (
    <main className="relative sm:flex sm:items-center sm:justify-center">
      <div className="relative sm:pb-16 sm:pt-8">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
            <div className="absolute inset-0">
              <img className="h-full w-full" src="/img/sprinter.svg" alt="" />
              <div className="absolute inset-0 bg-[color:rgba(30,23,38,0.5)] mix-blend-multiply" />
            </div>
            <div className="lg:pt-18 relative px-4 pb-8 pt-8 sm:px-6 sm:pb-14 sm:pt-16 lg:px-8 lg:pb-20">
              <h1 className="text-center text-mega font-extrabold tracking-tight sm:text-6xl lg:text-7xl">
                <Link
                  to="/analysis/start"
                  className="block uppercase text-white drop-shadow-md"
                >
                  Agile PT
                </Link>
              </h1>
              <p className="mx-auto mt-6 max-w-lg text-center text-xl text-white sm:max-w-3xl">
                Running Analysis
              </p>
            </div>
          </div>
          {userId ? (
            <div className="flex flex-col justify-center">
              <Form method="POST">
                <Button
                  variant="default"
                  className="mx-0 mt-4 w-full bg-blue-600 p-0"
                >
                  Get Started
                </Button>
              </Form>
              <div className="mt-4 text-center">
                <Link to="/exercise">Exercises</Link>
              </div>
              <div className="mt-4 text-center">
                <Link to="/analysis/list">Previous Analysis Results</Link>
              </div>
              <div className="mt-4 text-center">
                <Link to="/admin/rules">Rule Set</Link>
              </div>
            </div>
          ) : (
            <Button
              asChild
              variant="default"
              className="mt-4 w-full bg-blue-600"
            >
              <Link to="/login">Sign In</Link>
            </Button>
          )}
        </div>
      </div>
    </main>
  );
}
